var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"d-flex align-center subtitle-1 lightgray"},[_vm._v(" "+_vm._s(_vm.produto.id)+" - "+_vm._s(_vm.produto.descricao)+" - Ref. "+_vm._s(_vm.produto.referencia)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"button_2","depressed":"","dark":"","small":""},on:{"click":function($event){_vm.dialogTransferencia = true}}},[_vm._v(" "+_vm._s(_vm.$tc("global.transferencia"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-swap-horizontal ")])],1),_c('v-btn',{attrs:{"color":"button_1","depressed":"","dark":"","small":""},on:{"click":function($event){_vm.dialogAddMovimentacao = true}}},[_vm._v(" "+_vm._s(_vm.$tc("global.movimentacao"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-divider'),_c('v-card-text',[(!_vm.loading)?_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headersDepositos,"items":_vm.produto_saldos,"items-per-page":-1,"sort-by":['id']},on:{"click:row":_vm.openDepositoMovimentacoes},scopedSlots:_vm._u([{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_vm._v("Total:")]),_c('td'),_c('td'),_c('td',[_c('b',[_vm._v(_vm._s(_vm.saldoTotalDepositos))])])])]},proxy:true},(_vm.moeda_produto)?{key:"item.fob",fn:function(ref){
var item = ref.item;
return [(_vm.moeda_produto.sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("guarani")(item.fob))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("currency")(item.fob))+" ")])]}}:null,(_vm.moeda_produto)?{key:"item.cif",fn:function(ref){
var item = ref.item;
return [(_vm.moeda_produto.sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("guarani")(item.cif))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("currency")(item.cif))+" ")])]}}:null],null,true)}):_c('Carregando')],1),_c('v-dialog',{attrs:{"scrollable":""},model:{value:(_vm.modalMovimentacoes),callback:function ($$v) {_vm.modalMovimentacoes=$$v},expression:"modalMovimentacoes"}},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-1 lightgray"},[_vm._v(" "+_vm._s(_vm.deposito.deposito)+" / "+_vm._s(_vm.produto.descricao)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.modalMovimentacoes = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"items":_vm.movimentacoes,"items-per-page":15,"sort-by":['created_at'],"sort-desc":true},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [(item.op === 'E')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" mdi-plus-circle ")]):_vm._e(),(item.op === 'S')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v(" mdi-minus-circle ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created_at,"dd/MM/yyyy HH:mm:ss"))+" ")]}},{key:"item.qtde",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.qtde)+" ")]}},(_vm.moeda_produto)?{key:"item.fob",fn:function(ref){
var item = ref.item;
return [(_vm.moeda_produto.sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("guarani")((" " + item.fob)))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("currency")(item.fob))+" ")])]}}:null,(_vm.moeda_produto)?{key:"item.cif",fn:function(ref){
var item = ref.item;
return [(_vm.moeda_produto.sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("guarani")(item.cif))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("currency")(item.cif))+" ")])]}}:null,(_vm.moeda_produto)?{key:"item.preco",fn:function(ref){
var item = ref.item;
return [(_vm.moeda_produto.sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("guarani")(item.preco))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.moeda_produto.sigla)+" "+_vm._s(_vm._f("currency")(item.preco))+" ")])]}}:null,{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cliente)+" "),(item.evento_id)?_c('v-btn',{staticClass:"ml-1",attrs:{"to":{ path: ("/eventos/" + (item.evento_id)) },"icon":"","x-small":""}},[_c('v-icon',[_vm._v("mdi-link")])],1):_vm._e()]}},{key:"item.op",fn:function(ref){
var item = ref.item;
return [(item.op === 'E')?_c('span',[_vm._v("Entrada")]):(item.op === 'S')?_c('span',[_vm._v("Saída")]):_c('span',[_vm._v(_vm._s(item.op))])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$tc("global.saldo"))+":")]),_c('td'),_c('td',[_c('b',[_vm._v(_vm._s(_vm.saldoTotal))])])])]},proxy:true}],null,true)})],1)],1)],1),(_vm.dialogAddMovimentacao)?_c('DialogAddMovimentacao',{attrs:{"dialogAddMovimentacao":_vm.dialogAddMovimentacao,"produto":_vm.produto},on:{"update:dialogAddMovimentacao":function($event){_vm.dialogAddMovimentacao=$event},"update:dialog-add-movimentacao":function($event){_vm.dialogAddMovimentacao=$event},"fetch-inventario":_vm.getSaldoDepositos}}):_vm._e(),(_vm.dialogTransferencia)?_c('DialogTransferencia',{attrs:{"dialogTransferencia":_vm.dialogTransferencia,"produto":_vm.produto},on:{"update:dialogTransferencia":function($event){_vm.dialogTransferencia=$event},"update:dialog-transferencia":function($event){_vm.dialogTransferencia=$event},"fetch-inventario":_vm.getSaldoDepositos}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }