<template>
  <v-card outlined>
    <v-card-title class="d-flex align-center subtitle-1 lightgray">
      {{ produto.id }} - {{ produto.descricao }} - Ref.
      {{ produto.referencia }}

      <v-spacer></v-spacer>
      <v-btn
        color="button_2"
        depressed
        dark
        small
        class="mr-2"
        @click="dialogTransferencia = true"
      >
        {{ $tc("global.transferencia") }}
        <v-icon right> mdi-swap-horizontal </v-icon>
      </v-btn>
      <v-btn
        color="button_1"
        depressed
        dark
        small
        class=""
        @click="dialogAddMovimentacao = true"
      >
        {{ $tc("global.movimentacao") }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        v-if="!loading"
        :headers="headersDepositos"
        :items="produto_saldos"
        :items-per-page="-1"
        :sort-by="['id']"
        class="data-tables data-tables__row-click"
        @click:row="openDepositoMovimentacoes"
      >
        <template v-slot:body.append>
          <tr>
            <td>Total:</td>
            <td></td>
            <td></td>
            <td>
              <b>{{ saldoTotalDepositos }}</b>
            </td>
          </tr>
        </template>

        <template v-if="moeda_produto" v-slot:item.fob="{ item }">
          <span v-if="moeda_produto.sigla === 'G$'">
            {{ moeda_produto.sigla }}
            {{ item.fob | guarani }}
          </span>
          <span v-else>
            {{ moeda_produto.sigla }}
            {{ item.fob | currency }}
          </span>
        </template>
        <template v-if="moeda_produto" v-slot:item.cif="{ item }">
          <span v-if="moeda_produto.sigla === 'G$'">
            {{ moeda_produto.sigla }}
            {{ item.cif | guarani }}
          </span>
          <span v-else>
            {{ moeda_produto.sigla }}
            {{ item.cif | currency }}
          </span>
        </template>
      </v-data-table>
      <Carregando v-else />
    </v-card-text>

    <v-dialog v-model="modalMovimentacoes" scrollable>
      <v-card>
        <v-card-title class="subtitle-1 lightgray">
          {{ deposito.deposito }} / {{ produto.descricao }}
          <v-spacer></v-spacer>
          <v-btn icon @click="modalMovimentacoes = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-data-table
            :headers="headers"
            :items="movimentacoes"
            :items-per-page="15"
            :sort-by="['created_at']"
            :sort-desc="true"
            class="data-tables"
          >
            <template v-slot:item.created_at="{ item }">
              <v-icon color="success" class="mr-2" v-if="item.op === 'E'">
                mdi-plus-circle
              </v-icon>
              <v-icon color="error" class="mr-2" v-if="item.op === 'S'">
                mdi-minus-circle
              </v-icon>
              {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
            </template>
            <template v-slot:item.qtde="{ item }">
              {{ item.qtde }}
            </template>
            <template v-if="moeda_produto" v-slot:item.fob="{ item }">
              <span v-if="moeda_produto.sigla === 'G$'">
                {{ moeda_produto.sigla }}
                {{ (" " + item.fob) | guarani }}
              </span>
              <span v-else>
                {{ moeda_produto.sigla }}
                {{ item.fob | currency }}
              </span>
            </template>
            <template v-if="moeda_produto" v-slot:item.cif="{ item }">
              <span v-if="moeda_produto.sigla === 'G$'">
                {{ moeda_produto.sigla }}
                {{ item.cif | guarani }}
              </span>
              <span v-else>
                {{ moeda_produto.sigla }}
                {{ item.cif | currency }}
              </span>
            </template>
            <template v-if="moeda_produto" v-slot:item.preco="{ item }">
              <span v-if="moeda_produto.sigla === 'G$'">
                {{ moeda_produto.sigla }}
                {{ item.preco | guarani }}
              </span>
              <span v-else>
                {{ moeda_produto.sigla }}
                {{ item.preco | currency }}
              </span>
            </template>

            <template v-slot:item.cliente="{ item }">
              {{ item.cliente }}
              <v-btn
                v-if="item.evento_id"
                :to="{ path: `/eventos/${item.evento_id}` }"
                icon
                x-small
                class="ml-1"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.op="{ item }">
              <span v-if="item.op === 'E'">Entrada</span>
              <span v-else-if="item.op === 'S'">Saída</span>
              <span v-else>{{ item.op }}</span>
            </template>

            <template v-slot:body.append>
              <tr>
                <td>{{ $tc("global.saldo") }}:</td>
                <td></td>
                <td>
                  <b>{{ saldoTotal }}</b>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DialogAddMovimentacao
      v-if="dialogAddMovimentacao"
      :dialogAddMovimentacao.sync="dialogAddMovimentacao"
      :produto="produto"
      @fetch-inventario="getSaldoDepositos"
    />
    <DialogTransferencia
      v-if="dialogTransferencia"
      :dialogTransferencia.sync="dialogTransferencia"
      :produto="produto"
      @fetch-inventario="getSaldoDepositos"
    />
  </v-card>
</template>

<script>
import { getProdutoMovimentacoes } from "@/api/produtos/produtos.js";
import { fetchDepositos } from "@/api/produtos/produtos_depositos.js";
import { fetchSaldoDepositos } from "@/api/produtos/produtos_saldos.js";
import { mapState } from "vuex";

export default {
  name: "ProdutoInventario",

  props: {
    produto: {
      type: Object,
      required: true,
    },
  },

  components: {
    DialogAddMovimentacao: () =>
      import("./components/DialogAddMovimentacao.vue"),
    DialogTransferencia: () => import("./components/DialogTransferencia.vue"),
  },

  data() {
    return {
      produto_saldos: [],
      modalMovimentacoes: false,
      modalMovimentacao: false,
      movimentacoes: [],
      movimentacao: {},
      depositoSelecionado: null,
      transferencia: false,
      disableModal: false,
      classificacoes: [],
      selectDepositos: [],
      deposito: {},
      loading: false,
      moeda_produto: null,
      dialogAddMovimentacao: false,
      dialogTransferencia: false,
    };
  },

  computed: {
    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    headers() {
      return [
        {
          text: this.$tc("global.data"),
          value: "created_at",
        },
        {
          text: this.$tc("global.operacao"),
          value: "op",
        },
        {
          text: this.$tc("global.tipo"),
          value: "origem",
        },
        {
          text: this.$tc("global.nome"),
          value: "cliente_nome",
        },
        {
          text: this.$tc("global.observacao"),
          value: "obs",
        },
        {
          text: this.$tc("global.qtd"),
          value: "qtde",
        },
        {
          text: this.$tc("global.preco"),
          value: "preco",
        },
        {
          text: this.$tc("global.custo"),
          value: "fob",
        },
        {
          text: this.$tc("global.custoDespesa"),
          value: "cif",
        },
      ];
    },
    headersDepositos() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$tc("global.deposito"),
          value: "deposito",
        },
        {
          text: this.$tc("global.cidade"),
          value: "cidade_nome",
        },
        {
          text: this.$tc("global.custo"),
          value: "fob",
        },
        {
          text: this.$tc("global.custoDespesa"),
          value: "cif",
        },
        {
          text: this.$tc("global.estoque"),
          value: "saldo",
        },
      ];
    },

    saldoTotal() {
      let saldo = 0;
      this.movimentacoes.forEach((item) => {
        if (item.op === "E") {
          saldo += Number(item.qtde);
        }
        if (item.op === "S") {
          saldo -= Number(item.qtde);
        }
      });
      return saldo;
    },
    saldoTotalDepositos() {
      let saldo = 0;
      this.produto_saldos.forEach((item) => {
        saldo += Number(item.saldo);
      });
      return saldo;
    },
  },

  methods: {
    getSaldoDepositos() {
      this.loading = true;
      return fetchSaldoDepositos(`?produto_id=${this.produto.id}`)
        .then((response) => {
          this.produto_saldos = response;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getSelectDepositos() {
      return fetchDepositos()
        .then((response) => {
          this.selectDepositos = response;
        })
        .catch(() => {});
    },
    getMovimentacao(produto_id, depositoId) {
      return new Promise((resolve, reject) => {
        getProdutoMovimentacoes(
          `?produto_id=${produto_id}&deposito_id=${depositoId}`
        )
          .then((response) => {
            response.forEach((item) => {
              item.editCusto = false;
            });
            this.movimentacoes = response;

            resolve();
          })
          .catch((error) => {
            console.log(error);

            reject();
          });
      });
    },
    // updateMovimentacao(movimentacao) {
    //   let mov = this.lodash.cloneDeep(movimentacao);
    //   delete mov.serial;
    //   delete mov.cliente;
    //   delete mov.editCusto;
    //   delete mov.tipoevento;

    //   putProdutoMovimentacoes(mov.id, mov)
    //     .then(() => {})
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // addMovimentacao() {
    //   this.movimentacao.produto_id = this.produto_id;
    //   if (this.transferencia) {
    //     if (this.movimentacao.op) {
    //       delete this.movimentacao.op;
    //     }
    //     if (this.movimentacao.origem) {
    //       delete this.movimentacao.origem;
    //     }
    //   } else {
    //     if (this.movimentacao.deposito_destino_id) {
    //       delete this.movimentacao.deposito_destino_id;
    //     }

    //     if (this.movimentacao.op === "S") {
    //       this.movimentacao.origem = "SAIDA MANUAL";
    //     } else {
    //       this.movimentacao.origem = "ENTRADA MANUAL";
    //     }
    //   }

    //   this.disableModal = true;

    //   postProdutoMovimentacoes(this.movimentacao)
    //     .then(() => {
    //       this.movimentacao = {};
    //       this.getSaldoDepositos();
    //       if (this.transferencia) {
    //         this.transferencia = false;
    //       }
    //       this.modalMovimentacao = false;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.disableModal = false;
    //     });
    // },
    // abrirModalAddMov() {
    //   this.movimentacao.deposito_id = this.selectDepositos[0].id;
    //   this.modalMovimentacao = true;
    // },
    openDepositoMovimentacoes(item) {
      this.deposito = item;
      this.getMovimentacao(this.produto.id, item.deposito_id).then(() => {
        this.modalMovimentacoes = true;
      });
    },
  },
  async mounted() {
    this.getSaldoDepositos();
    await this.getSelectDepositos();
    if (this.moeda_empresa) {
      this.moeda_produto = this.moedas.find((moedas) => {
        return moedas.id_moeda === this.moeda_empresa;
      });
    }
  },
};
</script>

<style></style>
